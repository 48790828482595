import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

interface IProps extends RouteProps {
  component: any;
  hasPermission?: boolean;
}

function PrivateRoute({ component: Component, hasPermission, ...rest }: IProps) {
  const { state } = useLocation();
  const authToken = localStorage.getItem('accessToken');
  const isCheckedPermission = (state as any)?.checked;

  return (
    <Route
      {...rest}
      render={props =>
        authToken ? (
          isCheckedPermission || hasPermission !== false ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/403' }} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { referer: props.location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
