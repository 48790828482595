import { createSlice } from '@reduxjs/toolkit';
import { IVirtualTesterMonitor } from './VirtualTesterMonitor.type';

export interface VirtualTesterMonitor {
  isLoading: boolean;
  selectVTM: IVirtualTesterMonitor | undefined;
}

const initialState: VirtualTesterMonitor = {
  isLoading: false,
  selectVTM: undefined,
};

export const virtualTesterMonitorSlice = createSlice({
  name: 'virtual-tester-monitor',
  initialState,
  reducers: {
    setSelectVTM: (state, action) => {
      state.selectVTM = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setSelectVTM } = virtualTesterMonitorSlice.actions;

export default virtualTesterMonitorSlice.reducer;
