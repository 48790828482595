/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import Sidenav from './Sidenav';
import Header from './Header';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import './Layout.scss';
import { getUserModulePermissionAPI } from 'app/services/api/settingAdmin';
import { setUserPermissionWhenReloadPage } from 'app/pages/LoginPage/auth.slice';
import { lowerCaseKeys } from 'app/services/helper';
const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const dispatch = useAppDispatch();
  const isRunningDebugRunner = useAppSelector(state => state?.enginuityWorkbench?.isRunningDebugRunner);
  const isBreakingPoint = useAppSelector(state => state?.enginuityWorkbench?.isBreakingPoint);
  const isEdittingPageLayout = useAppSelector(state => state.AI.isEdittingPageLayout);

  const sidenavColor = '#e9e9e9';
  const sidenavType = '#002060';
  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');

  const miniSidebarClass = useMemo(() => {
    if (pathname == 'iframe') {
      return 'mini-sidebar';
    }
    return '';
  }, [pathname]);

  const handleWarningWhenEdittingPageLayout = () => {
    openNotificationWithIcon('warning', 'You must save or cancel editing the page layout before exit');
  };

  const getModulePermission = async () => {
    try {
      let res = await getUserModulePermissionAPI();
      let result = res?.data?.data?.map(item => lowerCaseKeys(item));
      dispatch(setUserPermissionWhenReloadPage(result));
    } catch (error) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  useEffect(() => {
    getModulePermission();
  }, []);

  useEffect(() => {
    const handleInvalidToken = e => {
      const accessToken = JSON.parse(window.localStorage.getItem('accessToken'));
      if (!accessToken) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
      }
    };
    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, []);

  return (
    <Layout
      className={`layout-dashboard ${miniSidebarClass} ${pathname === 'profile' ? 'layout-profile' : ''}
      ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''}`}
    >
      <div
        className="blur-when-edit-page-layout"
        style={{ display: isEdittingPageLayout ? 'block' : 'none' }}
        onClick={handleWarningWhenEdittingPageLayout}
      ></div>
      <AntHeader
        style={{
          position: 'fixed',
          zIndex: 100,
          width: '100%',
          pointerEvents: isRunningDebugRunner ? 'none' : 'unset',
        }}
      >
        <Header />
      </AntHeader>
      <Layout style={{ marginTop: 95 }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          width={miniSidebarClass ? 100 : 250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${sidenavType === '#fff' ? 'active-route' : ''}`}
          style={{
            background: sidenavType,
            pointerEvents: isRunningDebugRunner && !isBreakingPoint ? 'none' : 'unset',
          }}
        >
          <Sidenav color={sidenavColor} />
        </Sider>
        <Content className="content-ant" style={{ paddingTop: 9 }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Main;
