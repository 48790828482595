import { Button, Card, Col, Form, Input, message, Row, Typography } from 'antd';
import { confirmPasswordAPI } from 'app/services/api/auth';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
const { Text, Title } = Typography;
const NewPassword = () => {
  const history = useHistory();
  const localtion = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = async (e: any) => {
    setLoading(true);
    try {
      const email = new URLSearchParams(localtion.search).get('email');
      const token = new URLSearchParams(localtion.search).get('token');
      const { password, confirm } = e;
      const request = {
        email,
        newPassword: password,
        confirmPassword: confirm,
        resetCode: token,
      };
      await confirmPasswordAPI(request);
      message.success('New password has been updated');
      setLoading(false);
      history.push('/login');
    } catch (error: any) {
      message.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="newpassword">
      <Helmet>
        <title>New Password</title>
      </Helmet>
      <Row className="container" justify={'center'} align={'middle'}>
        <Col className="content" sm={18} md={18} lg={12} xs={18}>
          <Card className="card">
            <Row justify={'center'} className="cardrow">
              <Col span={24} className="col_imgHeader">
                <img src="/Img/Header.png" className="img_header" alt="" />
              </Col>
              <Col sm={16} lg={16} md={16} xs={16} style={{ alignContent: 'center' }}>
                <Title className="text_title">New Password</Title>
                <Text type="secondary" className="text_header">
                  Set the new password for your account so you can login and access all featuress.
                </Text>
                <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
                  <Form.Item
                    label={
                      <label className="label_form" htmlFor="new-password-input__password">
                        Enter new password
                      </label>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      { min: 8, message: 'Must be at least 8 characters long' },
                    ]}
                  >
                    <Input.Password
                      placeholder="8 characters at least"
                      className="input_password"
                      id="new-password-input__password"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <label className="label_form" htmlFor="new-password-input__confirmPassword">
                        Confirm password
                      </label>
                    }
                    name="confirm"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Password does not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="input_password"
                      placeholder="8 characters at least"
                      id="new-password-input__confirmPassword"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" className="confirm_button" loading={loading}>
                      <Text className="text_confirm_button">Create password</Text>
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={24} className="card_footer">
                <img src="/Img/Footer.png" className="img_footer" alt="" />
              </Col>
              <Text className="text_footer">© Copyright PinnacleQM 2014 - 2022. All Rights Reserved</Text>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default NewPassword;
