import React, { useEffect, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Collapse, Form, Input, Modal, Select, Tabs } from 'antd';

import AdditionalPermissionTab from './AdditionalPermissionTab';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { getListProjectAPI } from 'app/services/api/project';
import { getListRoleAPI, getUserRoleDetailAPI, updateUserRoleAPI } from 'app/services/api/settingAdmin';
import './UserDetail.scss';
import { IUserRoleDetail } from '../../Setting.type';

const { Panel } = Collapse;

type TEditUserRoleFormValues = {
  projects: {
    additionalPermissions: null; // check type
    projectId: number;
    projectName: string;
    projectRoleIds: number[];
  }[];
};

const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const UserDetail = () => {
  const history = useHistory();
  const { userId } = useParams<any>();
  const [form] = Form.useForm<TEditUserRoleFormValues>();

  const [userDetail, setUserDetail] = useState<IUserRoleDetail>();
  const [listRole, setListRole] = useState<DefaultOptionType[]>([]);
  const [listProject, setListProject] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getListProjects = async () => {
    try {
      const userDetailData = await getUserDetail();
      const currentProjectIds = userDetailData.userProjects.map(item => item.projectId);
      const { projects } = await getListProjectAPI();
      const projectOptions = projects.map(item => ({
        label: item.projectTitle,
        value: item.projectID,
        disabled: currentProjectIds.includes(item.projectID),
      }));
      setListProject(projectOptions);
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  const getListRole = async () => {
    try {
      const { data } = await getListRoleAPI();
      setListRole(data.data);
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  const getUserDetail = async () => {
    try {
      const { data } = await getUserRoleDetailAPI(userId);
      const currentProjects = data.data?.userProjects?.map(item => ({
        projectId: item.projectId,
        projectName: item.projectName,
        projectRoleIds: item.userProjectRoles.map(role => role.roleId),
        additionalPermissions: item.additionalPermissions,
      }));
      form.setFieldValue('projects', currentProjects);
      setUserDetail(data.data);
      return data.data;
    } catch (error: any) {
      openNotificationWithIcon('error', error.message);
    }
  };

  const getAllApi = async () => {
    setLoading(true);
    await Promise.all([getListRole(), getListProjects()]);
    setLoading(false);
  };

  const handleSave = async (values: TEditUserRoleFormValues) => {
    const newUserProject = values.projects?.map(item => {
      const userProjectRoles = listRole.filter(role => item.projectRoleIds?.includes(role?.roleId));
      return {
        projectId: item.projectId,
        projectName: item.projectName,
        additionalPermissions: item.additionalPermissions,
        userProjectRoles,
      };
    });
    const payload = {
      ...userDetail,
      userProjects: newUserProject,
    };

    try {
      const { data } = await updateUserRoleAPI(payload);
      history.push('/setting', { tab: 'users' });
      openNotificationWithIcon('success', data.message);
    } catch (error: any) {
      openNotificationWithIcon('error', error.message);
    }
  };

  useEffect(() => {
    getAllApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Card title={`Edit / ${userId}`} className="card-layout UserDetail">
      <div className="UserDetail__back" onClick={() => history.push('/setting', { tab: 'users' })}>
        <img src="/icons/back-icon.svg" alt="back-icon" style={{ height: 20, marginRight: 4 }} />
        Back
      </div>
      <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>
        <Tabs.TabPane tab="Edit role" key="1">
          <Form
            form={form}
            layout="vertical"
            className="UserDetail__form"
            initialValues={{ projects: [] }}
            onFinish={handleSave}
          >
            <div className="UserDetail__form-wrap">
              <Form.Item label="Username" htmlFor="edit-user-role__input-username">
                <Input value={userDetail?.userName} disabled />
              </Form.Item>
              <p>Roles</p>
              <Form.List name="projects">
                {(fields, { add }) => {
                  return (
                    <>
                      {fields.map((field, index) => {
                        const existedProjectName = form.getFieldValue(['projects', field.name, 'projectName']);

                        if (existedProjectName)
                          return (
                            <Collapse defaultActiveKey={index}>
                              <Panel header={existedProjectName} key={index}>
                                <Form.Item
                                  name={[field.name, 'projectRoleIds']}
                                  className="UserDetail__form-item-roles"
                                >
                                  <Select
                                    options={listRole.map(item => ({
                                      label: item.roleTitle,
                                      value: item.roleId,
                                    }))}
                                    mode="multiple"
                                    showArrow
                                    filterOption={filterOption}
                                    id={`user-detail-form__input-roles-${index}`}
                                  />
                                </Form.Item>
                              </Panel>
                            </Collapse>
                          );

                        return (
                          <Collapse collapsible="disabled" defaultActiveKey={index}>
                            <Panel header={null} key={index} showArrow={false} className="UserDetail__panel">
                              <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const currentProjects = getFieldValue('projects');
                                  const selectedProjectIds = currentProjects?.map(item => item?.projectId);
                                  const newProjectOptions = listProject.map(item => ({
                                    ...item,
                                    disabled: item.disabled ? item.disabled : selectedProjectIds.includes(item.value),
                                  }));

                                  return (
                                    <Form.Item label="Project" name={[field.name, 'projectId']}>
                                      <Select options={newProjectOptions} />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                              <Form.Item
                                label="Roles"
                                name={[field.name, 'projectRoleIds']}
                                className="UserDetail__form-item-roles"
                              >
                                <Select
                                  options={listRole.map(item => ({
                                    label: item.roleTitle,
                                    value: item.roleId,
                                  }))}
                                  mode="multiple"
                                  showArrow
                                />
                              </Form.Item>
                            </Panel>
                          </Collapse>
                        );
                      })}

                      <Form.Item>
                        <Button type="primary" onClick={() => add()} disabled={fields.length >= listProject.length}>
                          Add Project
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </div>
            <div className="UserDetail__form-footer">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Edit additional permission" key="2">
          <AdditionalPermissionTab userId={userId} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default UserDetail;
