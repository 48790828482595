import client from '.';

export const loginAPI = (account: string, password: string) => {
  return client.post('/Users/authenticate', { account, password }).then(res => res.data);
};

export const logoutAPI = () => {
  return client.post('/Users/logout').then(res => res);
};

export const selectProductLicenceAPI = (params: any) => {
  return client.post('/Users/select-product-licence', params).then(res => res.data);
};

export const forgotPasswordAPI = (username: string, host: string) => {
  return client.post('/Users/reset-password', { username, host }).then(res => res.data);
};
export const confirmPasswordAPI = request => {
  return client.post('/Users/confirm-reset-password', request).then(res => res.data);
};
export const getUserWithRoleAPI = params => {
  return client.get('/Users/get-user-with-role', { params: params }).then(res => res.data);
};
