import client from '.';

export const getReleaseRegisterGanttChartAPI = params => {
  return client.get('/ReleaseRegisters/get-release-gantt-chart', { params: params });
};

export const getReleaseRegisterGridChartAPI = params => {
  return client.post('/ReleaseRegisters/get-release-grid-chart', null, { params: params }).then(res => res.data);
};

// release folder view api
export const getReleaseFolderViewAPI = params => {
  return client.post('/ReleaseRegisters/get-release-folder-chart', null, { params: params }).then(res => res.data);
};

export const assignReleaseToFolderAPI = params => {
  return client.post('/ReleaseRegisters/assign-release-to-folder', params);
};

export const reparentReleaseAPI = params => {
  return client.post('/ReleaseRegisters/reparent-release', params);
};

// end release folder view api

export const apiGetMiniDashboard = (id: number) => {
  return client.get(`/Releases/get-release-mini-dashboard?id=${id}`).then(res => res.data);
};

export const getReleaseIssueStatusAPI = (id: number) => {
  return client.get(`/Releases/get-release-issue-status?id=${id}`).then(res => res.data);
};

export const getReleaseDetailAPI = (id: string) => {
  return client.get('/Releases/get-release-detail', { params: { id } });
};

export const addNewReleaseAPI = (param: any) => {
  return client.post('/Releases/create-release', { ...param });
};

export const updateReleaseAPI = (param: any) => {
  return client.post('/Releases/update-release', { ...param });
};

// release right
export const deleteReleaseAPI = params => {
  return client.delete('/Releases/delete-release', {
    data: params,
  });
};

// release release-excution-status

export const releaseExcutionStatusAPI = (id: number) => {
  return client.get(`/Releases/get-release-excution-status-v2?id=${id}`).then(res => res.data);
};

// release workflow
export const getWorkflowAPI = () => {
  return client.get(`/Releases/get-release-workflow`).then(res => res.data);
};

export const getUpdateWorkflowAPI = (param: string, id: number) => {
  return client.post(`/Releases/release-workflow-${param}?releaseId=${id}`).then(res => res.data);
};

export const getUpdateWorkflowAssignAPI = (title: string, param: any) => {
  return client.post(`/Releases/release-workflow-${title}`, { ...param }).then(res => res.data);
};

// release Summary
export const getReleaseTypeAPI = () => {
  return client.get(`/Releases/get-release-type`).then(res => res.data);
};

export const getBusinessImportanceTypeAPI = () => {
  return client.get(`/BusinessImportance`).then(res => res.data);
};

export const getAPIReleaseID = () => {
  return client.get(`/Releases/get-new-release-id`).then(res => res.data);
};

// activity release

export const getReleaseHistoryAPI = (id: string) => {
  return client.get('/Releases/get-release-history', { params: { releaseId: id } });
};

// release scope
export const getReleaseRequirementScopeAPI = (params: any) => {
  return client.get('/ReleaseRequiredmentScope/get-release-scope', { params: params });
};

export const addReleaseRequirementScopeAPI = (params: any) => {
  return client.post('/ReleaseRequiredmentScope/assign-release-requirement', params);
};

export const deleteReleaseRequirementScopeAPI = (params: any) => {
  return client.post('/ReleaseRequiredmentScope/delete-release-requirement', params);
};

// release defect coverage
export const getReleaseDefectCoverageAPI = (params: any) => {
  return client.get('/ReleaseDefectCoverage/get-release-defect', { params: params });
};

export const assignReleaseDefectAPI = (params: any) => {
  return client.post('/ReleaseDefectCoverage/assign-release-defect', params);
};

export const deleteReleaseScopeDefectAPI = (params: any) => {
  return client.post('/ReleaseDefectCoverage/delete-release-defect', params);
};

// Attachment
export const getListReleaseAttachmentAPI = params => {
  return client.get('/Attachment/get-release-attachment', { params: params });
};

export const getReleaseAttachmentDetailAPI = (id: string) => {
  return client.get('/Attachment/get-attachment-detail', { params: { attachmentId: id } });
};

export const createReleaseAttachmentAPI = (params: any) => {
  return client.post('/Attachment/create-update-attachment', params);
};
export const deleteReleaseAttachmentAPI = (attachmentId: string) => {
  return client.delete('/Attachment/delete-attachment', {
    params: { attachmentId: attachmentId },
  });
};

export const getUserSelectAPI = (isInProject?: boolean) => {
  return client.get(`/Users/get-user-select?isInProject=${isInProject ? isInProject : false}`).then(res => res.data);
};
