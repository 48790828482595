import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ExecutionCycleItem,
  IExecutionCycleDetail,
  TestLabExecutionCycleSummary,
  executionCycleSetting,
} from './TestLab.type';
import { getMiniDashboardAPI } from 'app/services/api/testLab';
import { IMiniDashboard } from 'app/models/userStories.model';

export interface TestLabState {
  executionCycleSetting: executionCycleSetting;
  isLoading: boolean;
  executionCycleSelectedId: number;
  executionCycleSelectedDetail?: ExecutionCycleItem | null;
  isUpdatEexecutionCycle: boolean;
  executionCycleDetail: IExecutionCycleDetail;
  isUpdatEexecutionCycleDetail: boolean;
  executionSummary: TestLabExecutionCycleSummary;
  isLoadingTestExecutionDetails: boolean;
  isUpdateTestCaseAllocation: boolean;
  isAssignToMe: boolean;
  listMiniDasboard: IMiniDashboard[];
  isLoadingMiniDashboard: boolean;
  isLoadingSopeTestExecution: boolean;
}

const initialState: TestLabState = {
  isLoading: false,
  executionCycleSetting: {} as Required<executionCycleSetting>,
  executionCycleDetail: {} as Required<IExecutionCycleDetail>,
  executionSummary: {} as Required<TestLabExecutionCycleSummary>,
  executionCycleSelectedId: 0,
  executionCycleSelectedDetail: undefined,
  isUpdatEexecutionCycle: false,
  isUpdatEexecutionCycleDetail: false,
  isLoadingTestExecutionDetails: false,
  isUpdateTestCaseAllocation: false,
  isAssignToMe: true,
  listMiniDasboard: [],
  isLoadingMiniDashboard: false,
  isLoadingSopeTestExecution: false,
};

export const getMiniDashboard = createAsyncThunk('release/getMiniDashboard', async (id: number) => {
  const response = await getMiniDashboardAPI(id);
  return response.miniDashboard;
});

export const testLabSlice = createSlice({
  name: 'test-lab',
  initialState,
  reducers: {
    setExecutionCycleDetail: (state, action) => {
      state.executionCycleDetail = action.payload;
    },
    setExecutionSummary: (state, action) => {
      state.executionSummary = action.payload;
    },
    setExecutionCycleSetting: (state, action) => {
      state.executionCycleSetting = action.payload;
    },
    setExecutionCycleSelectedId: (state, action) => {
      state.executionCycleSelectedId = action.payload;
    },
    setExecutionCycleSelectedDetail: (state, action) => {
      state.executionCycleSelectedDetail = action.payload;
    },
    triggerisUpdatEexecutionCycle: state => {
      state.isUpdatEexecutionCycle = !state.isUpdatEexecutionCycle;
    },
    triggerisUpdatEexecutionCycleDetail: state => {
      state.isUpdatEexecutionCycleDetail = !state.isUpdatEexecutionCycleDetail;
    },
    triggerIsLoadingTestExecutionDetails: state => {
      state.isLoadingTestExecutionDetails = !state.isLoadingTestExecutionDetails;
    },
    triggerIsUpdateTestCaseAllocation: state => {
      state.isUpdateTestCaseAllocation = !state.isUpdateTestCaseAllocation;
    },
    setAssignToMe: (state, action) => {
      state.isAssignToMe = action.payload;
    },
    triggerLoadingMiniChart: state => {
      state.isLoadingMiniDashboard = !state.isLoadingMiniDashboard;
    },
    triggerIsLoadingSopeTestExecution: state => {
      state.isLoadingSopeTestExecution = !state.isLoadingSopeTestExecution;
    },
  },
  extraReducers: builder => {
    builder.addCase(getMiniDashboard.fulfilled, (state, action) => {
      state.listMiniDasboard = action.payload;
      state.isLoadingMiniDashboard = false;
    });
  },
});

export const {
  setExecutionCycleSelectedId,
  setExecutionCycleSelectedDetail,
  setExecutionCycleDetail,
  setExecutionCycleSetting,
  setExecutionSummary,
  triggerisUpdatEexecutionCycle,
  triggerisUpdatEexecutionCycleDetail,
  triggerIsLoadingTestExecutionDetails,
  triggerIsUpdateTestCaseAllocation,
  triggerLoadingMiniChart,
  triggerIsLoadingSopeTestExecution,
  setAssignToMe,
} = testLabSlice.actions;

export default testLabSlice.reducer;
