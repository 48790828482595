import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { SelectionTypeReleaseScope } from 'app/constant';
import { IMiniDashboard } from 'app/models/release.model';
import {
  addNewReleaseAPI,
  addReleaseRequirementScopeAPI,
  apiGetMiniDashboard,
  assignReleaseDefectAPI,
  deleteReleaseRequirementScopeAPI,
  deleteReleaseScopeDefectAPI,
  getReleaseDefectCoverageAPI,
  getReleaseDetailAPI,
  getReleaseIssueStatusAPI,
  getReleaseRegisterGridChartAPI,
  getReleaseRequirementScopeAPI,
  releaseExcutionStatusAPI,
  updateReleaseAPI,
} from 'app/services/api/release';

export interface ReleaseState {
  isLoading: boolean;
  isLoadingMiniDashboard: boolean;
  isLoadingExecutionStatus: boolean;
  isLoadingIssueStatus: boolean;
  ReleaseSelectedId: any;
  ReleaseDetail: any;
  isUpdateRelease: boolean;
  listReleaseGrid: any[];
  listReleaseScopeInclusive: any[];
  listReleaseScopeExclusive: any[];
  listReleaseDefectCoverageInclusive: any[];
  listReleaseDefectCoverageExclusive: any[];
  listExcutionStatus: any;
  listMiniDasboard: IMiniDashboard[];
  listIssueStatus: any;
  isAssignToMe: boolean;
  isRefreshHistory: boolean;
  scrollHeaderRelease: number;
  scrollRightRelease: number;
}

const initialState: ReleaseState = {
  isLoading: false,
  isLoadingMiniDashboard: false,
  isLoadingExecutionStatus: false,
  isLoadingIssueStatus: false,
  ReleaseSelectedId: '',
  ReleaseDetail: null,
  isUpdateRelease: false,
  listReleaseGrid: [],
  listReleaseScopeInclusive: [],
  listReleaseScopeExclusive: [],
  listReleaseDefectCoverageInclusive: [],
  listReleaseDefectCoverageExclusive: [],
  listExcutionStatus: {},
  listMiniDasboard: [],
  listIssueStatus: {},
  isAssignToMe: true,
  isRefreshHistory: false,
  scrollHeaderRelease: 0,
  scrollRightRelease: 0,
};

export const getReleaseDetail = createAsyncThunk('release/getReleaseDetail', async (id: any, { rejectWithValue }) => {
  if (id) {
    try {
      const response = await getReleaseDetailAPI(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
});

export const addNewRelease = createAsyncThunk('release/addNewRelease', async (params: any) => {
  try {
    const res: any = await addNewReleaseAPI(params);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', error?.errors?.ReleaseLabel || error?.errors?.ReleaseTitle || error);
  }
});

export const updateRelease = createAsyncThunk('release/updateRelease', async (params: any) => {
  const response = await updateReleaseAPI(params);
  return response.data;
});

export const getReleaseRequirementScope = createAsyncThunk(
  'release/getReleaseRequirementScope',
  async (params: any) => {
    const response = await getReleaseRequirementScopeAPI(params);
    return response.data.releaseScope;
  },
);

export const getReleaseDefectCoverage = createAsyncThunk('release/getReleaseDefectCoverage', async (params: any) => {
  const response = await getReleaseDefectCoverageAPI(params);
  return response.data.releaseDefectCoverage;
});

// Activity Release scope
export const addReleaseScope = createAsyncThunk('release/addReleaseScope', async (params: any) => {
  const response = await addReleaseRequirementScopeAPI(params);
  return response.data;
});

export const deleteReleaseScope = createAsyncThunk('release/deleteReleaseScope', async (params: any) => {
  const response = await deleteReleaseRequirementScopeAPI(params);
  return response.data;
});

export const addReleaseDefectAPI = createAsyncThunk('release/addReleaseDefectAPI', async (params: any) => {
  const response = await assignReleaseDefectAPI(params);
  return response.data;
});

export const deleteReleaseDefectAPI = createAsyncThunk('release/deleteReleaseDefectAPI', async (params: any) => {
  const response = await deleteReleaseScopeDefectAPI(params);
  return response.data;
});

// Call API Chart
export const getReleaseMiniDashboard = createAsyncThunk('release/getReleaseMiniDashboard', async (id: number) => {
  const response = await apiGetMiniDashboard(id);
  return response.result;
});

export const getReleaseExcutionStatus = createAsyncThunk('release/getReleaseExcutionStatus', async (id: number) => {
  const response = await releaseExcutionStatusAPI(id);
  return response.releaseExcutionStatus;
});

export const getReleaseIssueStatus = createAsyncThunk('release/getReleaseIssueStatus', async (id: number) => {
  const response = await getReleaseIssueStatusAPI(id);
  return response;
});

export const releaseSlice = createSlice({
  name: 'release',
  initialState,
  reducers: {
    setReleaseSelectedId: (state, action) => {
      state.ReleaseSelectedId = action.payload;
    },
    triggerUpdateRelease: state => {
      state.isUpdateRelease = !state.isUpdateRelease;
    },
    clearReleaseDetail: state => {
      state.ReleaseDetail = null;
    },
    setAssignToMe: (state, action) => {
      state.isAssignToMe = action.payload;
    },
    triggerRefreshHistory: state => {
      state.isRefreshHistory = !state.isRefreshHistory;
    },
    setReleaseScrollHeader: (state, action) => {
      state.scrollHeaderRelease = action.payload;
    },
    setReleaseScrollRight: (state, action) => {
      state.scrollRightRelease = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getReleaseDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ReleaseDetail = action.payload.releaseDetail;
    });

    builder.addCase(getReleaseDetail.rejected, (state, action: any) => {
      if (action?.payload.includes('has been deleted')) {
        state.ReleaseSelectedId = '-1';
        state.ReleaseDetail = null;
      } else {
        state.ReleaseSelectedId = '';
      }
      state.isUpdateRelease = !state.isUpdateRelease;
    });

    builder.addCase(addNewRelease.fulfilled, (state, action) => {
      if (action?.payload) {
        state.ReleaseDetail = action?.payload?.releaseDetail;
        state.ReleaseSelectedId = action?.meta?.arg?.releaseId;
      }
    });

    builder.addCase(updateRelease.fulfilled, (_, action) => {
      openNotificationWithIcon('success', action.payload);
    });

    builder.addCase(updateRelease.rejected, (_, action) => {
      openNotificationWithIcon('error', action?.error?.message);
    });

    builder.addCase(getReleaseRequirementScope.fulfilled, (state, action) => {
      if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Inclusive) {
        state.listReleaseScopeInclusive = action.payload;
      } else if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Exclusive) {
        state.listReleaseScopeExclusive = action.payload;
      }
    });
    builder.addCase(getReleaseRequirementScope.rejected, (state, action) => {
      if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Inclusive) {
        state.listReleaseScopeInclusive = [];
      } else if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Exclusive) {
        state.listReleaseScopeExclusive = [];
      }
    });

    builder.addCase(getReleaseDefectCoverage.fulfilled, (state, action) => {
      if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Inclusive) {
        state.listReleaseDefectCoverageInclusive = action.payload;
      } else if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Exclusive) {
        state.listReleaseDefectCoverageExclusive = action.payload;
      }
    });
    builder.addCase(getReleaseDefectCoverage.rejected, (state, action) => {
      if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Inclusive) {
        state.listReleaseDefectCoverageInclusive = [];
      } else if (action.meta.arg.selectionType === SelectionTypeReleaseScope.Exclusive) {
        state.listReleaseDefectCoverageExclusive = [];
      }
    });

    // Activity
    builder.addCase(addReleaseScope.fulfilled, (state, action) => {
      state.isLoadingMiniDashboard = true;
      state.isLoadingExecutionStatus = true;
      state.isLoadingIssueStatus = true;
    });

    builder.addCase(deleteReleaseScope.fulfilled, (state, action) => {
      state.isLoadingMiniDashboard = true;
      state.isLoadingExecutionStatus = true;
      state.isLoadingIssueStatus = true;
    });

    builder.addCase(addReleaseDefectAPI.fulfilled, (state, action) => {
      state.isLoadingMiniDashboard = true;
      state.isLoadingExecutionStatus = true;
      state.isLoadingIssueStatus = true;
    });

    builder.addCase(deleteReleaseDefectAPI.fulfilled, (state, action) => {
      state.isLoadingMiniDashboard = true;
      state.isLoadingExecutionStatus = true;
      state.isLoadingIssueStatus = true;
    });

    // Chart ...........
    builder.addCase(getReleaseExcutionStatus.fulfilled, (state, action) => {
      state.listExcutionStatus = action.payload;
      state.isLoadingMiniDashboard = false;
      state.isLoadingExecutionStatus = false;
      state.isLoadingIssueStatus = false;
    });

    builder.addCase(getReleaseMiniDashboard.fulfilled, (state, action) => {
      state.listMiniDasboard = action.payload;
      state.isLoadingMiniDashboard = false;
      state.isLoadingExecutionStatus = false;
      state.isLoadingIssueStatus = false;
    });

    builder.addCase(getReleaseIssueStatus.fulfilled, (state, action) => {
      state.listIssueStatus = action.payload;
      state.isLoadingMiniDashboard = false;
      state.isLoadingExecutionStatus = false;
      state.isLoadingIssueStatus = false;
    });
  },
});

export const {
  setReleaseSelectedId,
  triggerUpdateRelease,
  clearReleaseDetail,
  setAssignToMe,
  triggerRefreshHistory,
  setReleaseScrollHeader,
  setReleaseScrollRight,
} = releaseSlice.actions;

export default releaseSlice.reducer;
