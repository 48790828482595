import { openNotificationWithIcon } from 'app/components/PopupNotification';

export const openNotification = (res: string) => {
  return openNotificationWithIcon('success', res);
};

export const openNotificationError = (res: string) => {
  return openNotificationWithIcon('error', res);
};

export const covertText = (text: string) => {
  return text.toLowerCase().replace(/[^\w\s]/gi, '');
};

export const covertTextHyphen = (text: string) => {
  return text.toLowerCase().replace(/\s+/g, '-');
};

export const getBase64Image = (event: any, maximum = 5) => {
  return new Promise<{ file: File; fileName: string; fullBase64: string } | undefined>((resolve, reject) => {
    const files = event.target.files;
    const file = files[0];
    if (file) {
      if (!file.type.includes('image/')) {
        event.target.value = null;
        reject('Only photos are allowed to be uploaded.');
      }
      if (file.size > maximum * 1024 * 1024) {
        event.target.value = null;
        reject(`Image size must not exceed ${maximum}MB.`);
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          // const strImage = reader.result?.toString().replace(/^data:image\/[a-z]+;base64,/, "");
          resolve({ file, fileName: file.name, fullBase64: reader.result?.toString() ?? '' });
        };
      }
    } else {
      resolve(undefined);
    }
  });
};

export const setBlockNav = (show: boolean) => {
  if (show) {
    const kt_aside_menu = document.getElementsByClassName('kt_aside_menu');
    kt_aside_menu[0]?.classList.add('show-block-nav');
  } else {
    const kt_aside_menu = document.getElementsByClassName('kt_aside_menu');
    kt_aside_menu[0]?.classList?.remove('show-block-nav');
  }
};

export const setBlockModalTestStep = (show: boolean, slidesToShow: number) => {
  if (show) {
    const elementTeststep = document
      .getElementsByClassName('ant-modal-mask')[0]
      ?.setAttribute('style', `${slidesToShow !== 0 ? 'width: 57%' : 'width: 44%; left: 57%'}`);
  } else {
    const elementTeststep = document.getElementsByClassName('elementTeststep');
    elementTeststep[0]?.classList?.remove('show-block-nav');
  }
};

export const setBlockModalMask = (show: boolean) => {
  if (show) {
    const elementTeststep = document.getElementsByClassName('ant-modal-mask')[0]?.setAttribute('style', 'width: 0%');
  }
};

export const isDisableItemMenu = (
  action?: string,
  item?:
    | 'Add'
    | 'Edit'
    | 'Delete'
    | 'Copy'
    | 'Cut'
    | 'Paste'
    | 'Insert'
    | 'Highlight'
    | 'Break Point'
    | 'Edit Page Details'
    | 'Declare a Transaction Loop',
  multiple?: boolean,
) => {
  switch (action) {
    case 'End':
      if (
        [
          'Add',
          'Edit',
          'Delete',
          'Copy',
          'Cut',
          'Paste',
          'Insert',
          'Highlight',
          'Break Point',
          'Edit Page Details',
        ].includes(item as string)
      ) {
        return true;
      }
      break;

    case 'Start':
      if (
        ['Edit', 'Delete', 'Copy', 'Cut', 'Paste', 'Highlight', 'Break Point', 'Edit Page Details'].includes(
          item as string,
        )
      ) {
        return true;
      }
      break;

    case 'Start Loop':
      if (
        ['Edit', 'Copy', 'Cut', 'Paste', 'Insert', 'Highlight', 'Break Point', 'Edit Page Details'].includes(
          item as string,
        )
      ) {
        return true;
      }
      break;

    case 'End Loop':
      if (
        ['Edit', 'Copy', 'Cut', 'Paste', 'Insert', 'Highlight', 'Break Point', 'Edit Page Details'].includes(
          item as string,
        )
      ) {
        return true;
      }
      break;

    case 'Break':
      if (['Break Point'].includes(item as string)) {
        return true;
      }
      break;

    default:
      break;
  }
};

export const setWhiteSpace = (value: string) => {
  if (/^\s+$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const toSlug = (str: string): string => {
  str = str?.toString()?.toLowerCase();

  str = str?.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  str = str?.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  str = str?.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  str = str?.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  str = str?.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  str = str?.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  str = str?.replace(/(đ)/g, 'd');

  str = str?.replace(/([^0-9a-z-\s])/g, '');

  str = str?.replace(/(\s+)/g, '-');

  str = str?.replace(/^-+/g, '');

  str = str?.replace(/-+$/g, '');
  str = str?.replace(/--+/g, '-');

  return str;
};

export const lowerCaseKeys = obj =>
  Object.keys(obj).reduce((acc, k) => {
    acc[k.toLowerCase()] = obj[k];
    return acc;
  }, {});
