import { INotificationCreate, INotificationDelete } from 'types/Dashboard/INotification';
import { IWorkListSearchParam } from 'types/Dashboard/IWorklist';
import client from '.';

export const getWorkListAPI = (params: IWorkListSearchParam) => {
  return client.post('/WorkLists/get-worklist', params).then(res => res.data);
};

export const getWorkListNumberAPI = () => {
  return client.get('/WorkLists/get-worklist-number').then(res => res.data);
};

export const getListNotificationAPI = () => {
  return client.get('/Notifications/get').then(res => res.data);
};

export const getListNotificationTypeAPI = () => {
  return client.get('/Notifications/get-notification-type').then(res => res.data);
};

export const createNotificationAPI = (params: INotificationCreate) => {
  return client.post('/Notifications/create', params).then(res => res.data);
};

export const createServiceRequestAPI = (message: string) => {
  return client
    .post('/Notifications/create-service-request', null, {
      params: { message },
    })
    .then(res => res.data);
};

export const deleteNotificationAPI = (params: INotificationDelete) => {
  return client.post('/Notifications/dismiss-notification', params).then(res => res.data);
};

export const updateStateNotificationAPI = params => {
  return client.post('/Notifications/read-notification', params).then(res => res.data);
};
