import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Collapse, Form, Select, Tag } from 'antd';

import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { getUserAdditionalPermissionAPI, updateUserAdditionalPermissionAPI } from 'app/services/api/settingAdmin';
import './AdditionalPermissionTab.scss';
import { IPermissionsModule, IProjectPermission } from '../../Setting.type';

const { Panel } = Collapse;

interface IProps {
  userId: string;
}

const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const getModuleOptions = (modules: IPermissionsModule[]) => {
  if (!modules.length) return [];

  return modules.map(item => ({
    label: item.moduleName,
    value: item.moduleId,
    options: item.displayPermissions?.map(data => ({
      label: data.name,
      value: `${item.moduleName}=moduleName=moduleId=${item.moduleId}=permissionId=${data.id}`,
    })),
  }));
};

const AdditionalPermissionTab = ({ userId }: IProps) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [additionalPermissions, setAdditionalPermissions] = useState<IProjectPermission[]>([]);

  const getAdditionalPermissions = async () => {
    try {
      const { data } = await getUserAdditionalPermissionAPI(userId);
      setAdditionalPermissions(data?.data);
      const newFormValues: { [key: string]: string[] } = {};
      data?.data.forEach(item => {
        const linkedModules = item.modules.filter(module => module.linkedPermissions.length > 0);
        if (!linkedModules) return;

        const newPermissions: any = [];
        linkedModules.forEach(element => {
          const permissions = element.linkedPermissions.map(
            item => `${element.moduleName}=moduleName=moduleId=${element.moduleId}=permissionId=${item.id}`,
          );
          newPermissions.push(...permissions);
        });
        newFormValues[item.projectId] = newPermissions;
        form.setFieldValue(item.projectId, newPermissions);
      });
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  const handleSubmitForm = async values => {
    const projectPermissions = Object.entries(values).map(([projectId, values]: any) => {
      const modules: Array<{ moduleId: string; linkedPermissions: string[] }> = [];
      values.forEach(element => {
        const moduleId = element.split('=moduleId=')[1].split('=permissionId=')[0];
        const permissionId = element.split('=permissionId=')[1];
        const elementIndex = modules.findIndex(module => module.moduleId === moduleId);
        if (!modules.length || elementIndex < 0) {
          const newModule = {
            moduleId,
            linkedPermissions: [permissionId],
          };
          modules.push(newModule);
        } else {
          const newModule = {
            moduleId,
            linkedPermissions: [...modules[elementIndex].linkedPermissions, permissionId],
          };
          modules[elementIndex] = newModule;
        }
      });
      return {
        projectId,
        modules,
      };
    });
    const payload = {
      userId,
      projectAdditionalPermissions: projectPermissions,
    };

    try {
      const { data } = await updateUserAdditionalPermissionAPI(payload);
      history.push('/setting', { tab: 'users' });
      openNotificationWithIcon('success', data.message);
    } catch (error: any) {
      openNotificationWithIcon('error', error.message);
    }
  };

  useEffect(() => {
    getAdditionalPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className="AdditionalPermissionTab">
      <Form layout="vertical" form={form} onFinish={handleSubmitForm} className="AdditionalPermissionTab__form">
        <div className="AdditionalPermissionTab__form-wrap">
          {additionalPermissions?.length
            ? additionalPermissions.map(item => (
                <Collapse defaultActiveKey={item.projectId}>
                  <Panel header={item.projectName} key={item.projectId}>
                    <Form.Item
                      name={item.projectId}
                      label="Additional Permission"
                      className="AdditionalPermissionTab__form-item-roles"
                    >
                      <Select
                        options={getModuleOptions(item.modules)}
                        mode="multiple"
                        showArrow
                        filterOption={filterOption}
                        tagRender={props => {
                          const moduleName = props.value.split('=moduleName=')[0];
                          return (
                            <Tag {...props} className="AdditionalPermissionTab__form-tag">
                              <span style={{ fontWeight: 500 }}>{moduleName}</span> - {props.label}
                            </Tag>
                          );
                        }}
                        id={`additional-permission-form__input-${item.projectId}`}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
              ))
            : null}
        </div>
        <div className="AdditionalPermissionTab__form-footer">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AdditionalPermissionTab;
