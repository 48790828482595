import React from 'react';
import { Helmet } from 'react-helmet-async';

export function Iframe() {
  return (
    <>
      <Helmet>
        <title>Myenterpriselens</title>
      </Helmet>
      <div className="flex-space-between">
        <iframe className="iframe-enterprise" src="https://dev.myenterpriselens.com/login/?redirect=/" />
      </div>
    </>
  );
}
