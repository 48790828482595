import { message } from 'antd';
import React from 'react';
type NotificationType = 'success' | 'warning' | 'error';

export const openNotificationWithIcon = (type: NotificationType, content, duration = 5) => {
  message.destroy();
  message[type](<div id="notification-content">{content}</div>, duration);
  message.config({
    maxCount: 1,
  });
};
