import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAppComponent, IAppPage, IApplicationAI, IPageType } from './AI.type';
import { openNotificationWithIcon } from 'app/components/PopupNotification';

export interface IAIState {
  isLoading: boolean;
  applicationSelected?: IApplicationAI;
  componentSelected?: string;
  pageTypeSelected?: string;
  applicationPageSelected?: string;
  tabPageSelected?: string;
  appComponentList: IAppComponent[];
  pageTypeList: IPageType[];
  appPageList: IAppPage[];
  automationId: string;
  isCapturing: boolean;
  isReloadListPageName: boolean;
  isEdittingPageLayout: boolean;
  isClickChangeApplication: boolean;
  isReLoadListApplication: boolean;
}

const initialState: IAIState = {
  isLoading: false,
  applicationSelected: undefined,
  componentSelected: '',
  pageTypeSelected: '',
  applicationPageSelected: '',
  tabPageSelected: '',
  appComponentList: [],
  pageTypeList: [],
  appPageList: [],
  automationId: '',
  isCapturing: false,
  isReloadListPageName: false,
  isEdittingPageLayout: false,
  isClickChangeApplication: false,
  isReLoadListApplication: false,
};
export const AISlice = createSlice({
  name: 'AI',
  initialState,
  reducers: {
    setApplicationSelected: (state, action) => {
      state.applicationSelected = action.payload;
    },
    setComponentSelected: (state, action) => {
      state.componentSelected = action.payload;
    },
    setPageTypeSelected: (state, action) => {
      state.pageTypeSelected = action.payload;
    },
    setApplicationPageSelected: (state, action) => {
      state.applicationPageSelected = action.payload;
    },
    setTabPageSelected: (state, action) => {
      state.tabPageSelected = action.payload;
    },
    setAutomationId: (state, action) => {
      state.automationId = action.payload;
    },
    setAppComponentList: (state, action) => {
      state.appComponentList = action.payload;
    },
    setPageTypeList: (state, action) => {
      state.pageTypeList = action.payload;
    },
    setAppPageList: (state, action) => {
      state.appPageList = action.payload;
    },
    setIsCapturing: (state, action) => {
      state.isCapturing = action.payload;
    },
    setReloadListPageName: state => {
      state.isReloadListPageName = !state.isReloadListPageName;
    },
    setIsEdittingPageLayout: (state, action) => {
      state.isEdittingPageLayout = action.payload;
    },
    setIsClickChangeApplication: (state, action) => {
      state.isClickChangeApplication = action.payload;
    },
    setIsReloadListApplication: state => {
      state.isReLoadListApplication = !state.isReLoadListApplication;
    },
    goEditPageDetail: (state, action) => {
      state.applicationSelected = {
        application: action?.payload?.application,
        hasComponent: action?.payload?.hasComponent,
        applicationPageTypes: [],
      };
      state.componentSelected = action?.payload?.component;
      state.applicationPageSelected = action?.payload?.pageName;
      state.pageTypeSelected = action?.payload?.pageType;
      state.tabPageSelected = action?.payload?.tabName;
      state.automationId = action?.payload?.automationId;
    },
  },
  extraReducers: builder => {},
});

export const {
  setApplicationSelected,
  setComponentSelected,
  setPageTypeSelected,
  setApplicationPageSelected,
  setTabPageSelected,
  setAppComponentList,
  setPageTypeList,
  setAppPageList,
  setAutomationId,
  setIsCapturing,
  setReloadListPageName,
  setIsEdittingPageLayout,
  setIsClickChangeApplication,
  setIsReloadListApplication,
  goEditPageDetail,
} = AISlice.actions;

export default AISlice.reducer;
