import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ItTestCaseRun, ItestStepDetailDefect } from './Execute.type';
import { getSettingAPI } from 'app/services/api/bug';

export interface ExecuteState {
  isUpdateTestCase: boolean;
  isReloadAssignedExecution: boolean;
  isReloadSelectedExecutionCycle: boolean;
  executionSetId: number;
  executionSetRunId: number;
  testCaseSelectedId: number;
  testCaseRunId: number;
  testCaseDetail: ItTestCaseRun;
  testCaseDetailTitle: string;
  testStepId: number;
  testStepRunId: number;
  testStepDetailDefect: ItestStepDetailDefect;
  filterYourAssignedExecution: any;
}
const initialState: ExecuteState = {
  isUpdateTestCase: false,
  isReloadAssignedExecution: false,
  isReloadSelectedExecutionCycle: false,
  executionSetId: 0,
  executionSetRunId: 0,
  testCaseSelectedId: 0,
  testCaseRunId: 0,
  testCaseDetail: {} as Required<ItTestCaseRun>,
  testCaseDetailTitle: '',
  testStepId: 0,
  testStepRunId: 0,
  testStepDetailDefect: {} as Required<ItestStepDetailDefect>,
  filterYourAssignedExecution: [],
};

export const getSetting = createAsyncThunk('release/getSetting', async () => {
  const response = await getSettingAPI();
  return response;
});

export const executeSlice = createSlice({
  name: 'execute',
  initialState,

  reducers: {
    triggerIsUpdateTestCase: state => {
      state.isUpdateTestCase = !state.isUpdateTestCase;
    },
    triggerIsReloadAssignedExecution: state => {
      state.isReloadAssignedExecution = !state.isReloadAssignedExecution;
    },
    triggerIsReloadSelectedExecutionCycle: state => {
      state.isReloadSelectedExecutionCycle = !state.isReloadAssignedExecution;
    },
    setExecutionSetId: (state, action) => {
      state.executionSetId = action.payload;
    },
    setExecutionSetRunId: (state, action) => {
      state.executionSetRunId = action.payload;
    },
    setTestCaseSelectedId: (state, action) => {
      state.testCaseSelectedId = action.payload;
    },
    setTestCaseRunId: (state, action) => {
      state.testCaseRunId = action.payload;
    },
    settestCaseDetail: (state, action) => {
      state.testCaseDetail = action.payload;
    },
    setTestStepId: (state, action) => {
      state.testStepId = action.payload;
    },
    setFilterYourAssignedExecution: (state, action) => {
      state.filterYourAssignedExecution = action.payload;
    },
    setTestStepRunId: (state, action) => {
      state.testStepRunId = action.payload;
    },
    setTestStepDetailDefect: (state, action) => {
      state.testStepDetailDefect = action.payload;
    },
    clearTestStepDetailDefect: state => {
      state.testStepDetailDefect = initialState.testStepDetailDefect;
    },
  },
  extraReducers: builder => {},
});

export const {
  triggerIsUpdateTestCase,
  triggerIsReloadAssignedExecution,
  triggerIsReloadSelectedExecutionCycle,
  setExecutionSetId,
  setExecutionSetRunId,
  setTestCaseRunId,
  setTestCaseSelectedId,
  settestCaseDetail,
  setTestStepId,
  setFilterYourAssignedExecution,
  setTestStepRunId,
  setTestStepDetailDefect,
  clearTestStepDetailDefect,
} = executeSlice.actions;

export default executeSlice.reducer;
