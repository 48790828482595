import React, { useEffect, useState } from 'react';
import { Card, Form, Input } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { getPermissionDetailAPI, updatePermissionAPI } from 'app/services/api/settingAdmin';

import '../Permission.scss';
import './PermissionDetail.scss';
import TextArea from 'antd/lib/input/TextArea';
export default function PermissionDetail() {
  const history = useHistory();
  let { id } = useParams<any>();
  const [form] = Form.useForm();
  const [listLinkModule, setListLinkModule] = useState([]);
  useEffect(() => {
    getPermissionDetail();
  }, []);

  const getPermissionDetail = async () => {
    try {
      let params = {
        id: id,
      };
      let res = await getPermissionDetailAPI(params);
      let data = res?.data?.data;
      setListLinkModule(data.linkedModules);
      form.setFieldsValue({
        name: data?.name,
        description: data?.description,
        statusStr: data?.statusStr,
        linkedModules: data?.linkedModules,
      });
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  const handleUpadtePermission = async values => {
    try {
      let params = {
        id: id,
        name: values?.name?.trim(),
        description: values?.description?.trim(),
      };
      let res = await updatePermissionAPI(params);
      history.replace('/setting');
      openNotificationWithIcon('success', res?.data?.message);
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  return (
    <Card title={`Edit`} className="card-layout permission-detail">
      <div className="permission-detail__back" onClick={() => history.goBack()}>
        <img src="/icons/back-icon.svg" alt="back-icon" style={{ height: 20, marginRight: 4 }} />
        Back
      </div>
      <Form layout="vertical" form={form} onFinish={handleUpadtePermission}>
        <Form.Item
          name="name"
          label="Permission name"
          // rules={[{ required: true, whitespace: true, message: 'Permission name has not been entered.' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="description" label="Permission description">
          <TextArea rows={2} style={{ resize: 'none' }} />
        </Form.Item>
        <Form.Item name="statusStr" label="Status">
          <Input disabled />
        </Form.Item>
        <Form.Item name="linkedModules" label="Linked modules">
          <div className="permission-detail__link-module">
            {listLinkModule.map((item, index) => (
              <span className="setting-permission__link-module" style={{ marginRight: 10 }}>
                <span className="setting-permission__dot"></span>
                {item}
                {(index + 1) % 4 === 0 ? <br /> : null}
              </span>
            ))}
          </div>
        </Form.Item>
        <Form.Item>
          <div className="permission-detail__button">
            {/* <button
              type="button"
              className="ant-btn ant-btn-default"
              id="permission-detail__cancel"
              style={{ marginRight: 5 }}
              onClick={() => history.replace('/setting')}
            >
              <span>Cancel</span>
            </button> */}
            <button type="submit" className="ant-btn ant-btn-primary" id="permission-detail__save">
              <span>Save</span>
            </button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
}
