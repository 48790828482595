import { createSlice } from '@reduxjs/toolkit';

export interface AcceptanceArticleState {
  isLoading: boolean;
  acceptanceArticleSelectedId: number;
  isUpdateAcceptance: boolean;
  isUpdateAcceptanceArticle: boolean;
  isAssignToMeBusinessAcceptance: boolean;
  isAssignToMeAcceptanceItem: boolean;
}

const initialState: AcceptanceArticleState = {
  isLoading: false,
  acceptanceArticleSelectedId: 0,
  isUpdateAcceptance: false,
  isUpdateAcceptanceArticle: false,
  isAssignToMeBusinessAcceptance: true,
  isAssignToMeAcceptanceItem: true,
};

export const acceptanceArticleSlice = createSlice({
  name: 'acceptanceArticle',
  initialState,
  reducers: {
    setAcceptanceArticleSelectedId: (state, action) => {
      state.acceptanceArticleSelectedId = action.payload;
    },
    setUpdateAcceptance: state => {
      state.isUpdateAcceptance = !state.isUpdateAcceptance;
    },
    setUpdateAcceptanceArticle: state => {
      state.isUpdateAcceptanceArticle = !state.isUpdateAcceptanceArticle;
    },
    setIsAssignToMeBusinessAcceptance: (state, action) => {
      state.isAssignToMeBusinessAcceptance = action.payload;
    },
    setIsAssignToMeAcceptanceItem: (state, action) => {
      state.isAssignToMeAcceptanceItem = action.payload;
    },
  },

  extraReducers: builder => {},
});

export const {
  setAcceptanceArticleSelectedId,
  setUpdateAcceptance,
  setUpdateAcceptanceArticle,
  setIsAssignToMeBusinessAcceptance,
  setIsAssignToMeAcceptanceItem,
} = acceptanceArticleSlice.actions;

export default acceptanceArticleSlice.reducer;
